import React from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'

import { Box, Divider, Text } from '@chakra-ui/react'
import MotionBox from '../components/MotionBox'

import CTA from './CTA'
import CTA2 from './CTA2'

import paths from '../paths'

// TODO: Fix typings here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Variants = { open: any; closed: any; closedHero?: any }

const closed = {
  opacity: 0,
  transitionEnd: {
    display: 'none',
  },
}

const containerVariants: Variants = {
  open: {
    opacity: 1,
    display: 'flex',
  },
  closed,
  closedHero: closed,
}

type NavProps = {
  handleClick: () => void
}

const Nav: React.FC<NavProps> = ({ handleClick }) => {
  const location = useLocation()

  return (
    <MotionBox
      flexDirection="column"
      flexWrap="nowrap"
      width="fit-content"
      m="0 auto !important"
      mb="0"
      height="100%"
      justifyContent="center"
      alignItems="center"
      variants={containerVariants}
    >
      <Box m="0 0 2rem 0" w="100%">
        {paths.map((path, index) => (
          <Box w="100%" key={path.path} textAlign="center">
            <Text
              as={Link}
              to={path.path}
              fontSize="2xl"
              color="primary"
              fontWeight={location.pathname === path.path ? 'semiBold' : 'medium'}
              onClick={handleClick}
              textTransform="uppercase"
              lineHeight="2.5"
              opacity={location.pathname === path.path ? 1 : 0.7}
              _hover={{ opacity: 1 }}
            >
              {path.name}
            </Text>
            {index === paths.length - 1 ? null : (
              <Divider border="separator" borderColor="separator" />
            )}
          </Box>
        ))}
      </Box>
      <CTA2  position="relative" onClick={handleClick}/>
      <Text mt="0.05rem" mb="0.05rem" color="transparent">f</Text>
      <CTA onClick={handleClick}/>
      <Text mt="2rem" mb="1rem" color="transparent">g</Text>
    </MotionBox>
  )
}

export default Nav
