import React, { useEffect, useState } from 'react'
import { Box, ChakraProvider, Flex } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'

import theme from '../theme'
import Header from './Header'
import Footer from './Footer'

import { headerHeight } from './Header'

import 'typeface-cabin'
import 'leaflet/dist/leaflet.css'
import { GlobalStateContext } from '../hooks/useGlobalState'

const Index: React.FC = ({ children }) => {
  const [pdfUrl, setPdfUrl] = useState(null) // GlobalStateContext
  const value = { pdfUrl, setPdfUrl }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const smoothscroll = require('smoothscroll-polyfill')
    smoothscroll.polyfill()

    const setDocHeight = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`)
      document.documentElement.style.setProperty('--headerHeight', `${headerHeight}px`)
    }

    setDocHeight()
    //window.addEventListener('resize', setDocHeight)
    window.addEventListener('orientationchange', setDocHeight)
  }, [])

  const methods = useForm()

  return (
    <ChakraProvider resetCSS theme={theme}>
      <GlobalStateContext.Provider value={value}>
        <FormProvider {...methods}>
          <Header />
          <Flex
            w="100%"
            direction="column"
            wrap="nowrap"
            overflow="hidden"
            alignItems="stretch"
            minH="calc(var(--vh, 1vh) * 100)"
          >
            {children}
            <Box flex="1 1 0px" />
            <Footer />
          </Flex>
        </FormProvider>
      </GlobalStateContext.Provider>
    </ChakraProvider>
  )
}

export default Index
