type Path = {
  name: string
  path: string
}

const paths: Path[] = [
  {
    name: 'Inicio',
    path: '/',
  },
  {
    name: 'Preguntas frecuentes',
    path: '/faq/',
  },
  {
    name: 'Precios',
    path: '/precios/',
  },
  {
    name: 'Contacto',
    path: '/contacto/',
  },
]

export default paths
