import React, { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'

import { Box, Flex } from '@chakra-ui/react'

import Logo from './Logo'
import MenuButton from './MenuButton'
import Nav from './Nav'

import MotionBox from './MotionBox'
import { useViewportScroll } from 'framer-motion'

export const headerHeight = 76

const closed = {
  height: `${headerHeight}px`,
  transition: {
    when: 'afterChildren',
    ease: 'easeInOut',
    duration: 0.6,
  },
}

const containerVariants = {
  open: {
    height: '100vh',
    transition: {
      ease: 'easeInOut',
      duration: 0.3,
      when: 'beforeChildren',
      delaChildren: 0.3,
    },
  },
  closed,
  closedHero: closed,
}

const Header: React.FC = (): JSX.Element => {
  const { scrollY } = useViewportScroll()
  const location = useLocation()
  const [navOpen, setNavOpen] = useState(false)
  const [onTop, setOnTop] = useState(true)
  const [isHome, setIsHome] = useState(location.pathname === '/')
  const [currentVariant, setCurrentVariant] = useState('closedHero')

  const toggleNav = () => {
    setNavOpen(!navOpen)

    const body = document.getElementsByTagName('body')[0]
    body.classList.toggle('lock-scroll')
  }

  const closeNav = () => {
    setNavOpen(false)

    const body = document.getElementsByTagName('body')[0]
    body.classList.remove('lock-scroll')
  }

  useEffect(() => {
    if (location && location.pathname) {
      setIsHome(location.pathname === '/')
    }
  }, [location, location.pathname])

  useEffect(() => {
    const checkTop = () => {
      if (scrollY.get() < headerHeight) {
        if (!onTop) setOnTop(true)
      } else {
        if (onTop) setOnTop(false)
      }
    }

    checkTop()
    const unsusbscribe = scrollY.onChange(checkTop)

    return () => {
      unsusbscribe()
    }
  }, [onTop, setOnTop])

  useEffect(() => {
    const getCurrentVariant = () => {
      if (navOpen) return 'open'
      if (isHome && onTop) return 'closedHero'
      return 'closed'
    }
    setCurrentVariant(getCurrentVariant())
  }, [onTop, isHome, navOpen])

  const white = currentVariant === 'closedHero'

  return (
    <MotionBox
      as="header"
      initial={false}
      variants={containerVariants}
      animate={currentVariant}
      w="100%"
      position="fixed"
      zIndex="99"
    >
      <Box
        position="absolute"
        zIndex="-1"
        w="100%"
        h="100%"
        transition="all 0.3s ease-in-out"
        bg={white ? 'rgba(255, 255, 255, 0)' : 'navBG'}
      />
      <Flex
        justifyContent="space-between"
        alignItems="center"
        h="76px"
        w="100%"
        alignSelf="flex-start"
      >
        <Link to="/">
          <Logo white={white} />
        </Link>
        <MenuButton navOpen={navOpen} handleClick={() => toggleNav()} white={white} />
      </Flex>
      <Nav handleClick={() => closeNav()} />
    </MotionBox>
  )
}

export default Header
