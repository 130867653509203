/* eslint-disable prettier/prettier */
import React from 'react'
import { Link } from 'gatsby'
import { Link as ChakraLink, useTheme } from '@chakra-ui/react'
import { motion } from 'framer-motion'

const MotionLink = motion(ChakraLink)

type CTAProps = {
  onClick?: () => void
}

const CTA: React.FC<CTAProps> = ({ onClick }) => {
  const theme = useTheme()

  return (
    <MotionLink
      
      as={Link}
      transition={{ type: 'spring', duration: 0.05 }}
      whileHover={{
        scale: 1.05,
        boxShadow: theme.shadows['x1'],
      }}
      whileTap={{ scale: 0.95 }}
      layerStyle="cta"
      mb="3rem"
      textStyle="cta"
      onClick={onClick}
      to="/alquilar"
      textDecoration="none !important"
      color="white"
      boxShadow="md"
    >
      Alquilar una unidad

    </MotionLink>

  )
}

export default CTA